import React, { Fragment, useEffect } from "react";
//import "../../public/assets/scss/landing.scss";
import Head from "next/head";
// import Custom Components
import Header from "../containers/common/header";
import FooterSection from "../common/components/common/footer";
import { ServicesData } from "../database/services/database";

import BannerSection from "../common/components/section/banner";
import ServicesSection from "../common/components/section/services";
import ProductSolution from "../common/components/section/productSolution";
import ServicesSection2 from "../common/components/section/feature";
import ServicesSection3 from "../common/components/section/service";
import TestimonialSection from "../common/components/section/testimonial";
import AboutSection from "../common/components/section/about";
//import DownloadSection from "./layouts/sections/index/download";
//import ScreenShotsSection from './layouts/sections/index/screenshots'
//import PriceSection from './layouts/sections/index/price'
//import VideoSection from './layouts/sections/index/video'
// import GiftSection from '../landing/git-section'
// import DemoSection from '../landing/demo'
// import PortfolioSection from '../landing/portfolio'
// import WhatinsideSection from '../landing/whatinside'
// import BlogSection from '../landing/blog'
// import ElementSection from '../landing/elements'
// import FeaturesSection from '../landing/features'
// import TestimonialSection from '../landing/testimonial'
// import RatSection from '../landing/rat'
// import FooterSection from '../landing/footer'

const Home = () => {
  useEffect(() => {
    document.body.style.setProperty("--primary", "#357fef");
    document.body.style.setProperty("--secondary", "#4e4e4e");
    document.body.style.setProperty("--light", "#13B8EA");
    document.body.style.setProperty("--dark", "#4E56F3");
  });

  return (
    <Fragment>
      <div>
        <Head>
          <title>NIDIK WEBCON</title>
        </Head>
        <Header className="app1" />

        <BannerSection />

        <ServicesSection data={ServicesData[0]} />

        
        <ProductSolution />

        <AboutSection />

        <ServicesSection data={ServicesData[1]} />

        <ServicesSection3 />

        <TestimonialSection />

        <ServicesSection2 />

        <FooterSection />
      </div>
    </Fragment>
  );
};

export default Home;
