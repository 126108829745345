module.exports = {
  ProductSolutionData: [
    {
      id: "billing-software",
      icon: "/assets/images/app_landing2/service/2-1.png",
      title: "Billing Software",
      short:
        "Billing software is designed to record actions which are taken place in small to medium level of hotels...",
      details:
        "Billing software is designed to record actions which are taken place in small to medium level of hotels like customer enquiries, check in, advance receipts, balance payment, check out, invoicing, etc. The software is designed as per the requirements of small and large scale busness in secured way with less process time needed in generating output reports.",
      bannerImage: "/assets/images/product/billing-software.png",
      line1: "",
      line2: "",
    },
    {
      id: "hotel-management-system",
      icon: "/assets/images/app_landing2/service/2-2.png",
      title: "Hotel Management System",
      short:
        "Hotel Management & Billing software is designed to record actions which are taken place in small...",
      details:
        "Hotel Management & Billing software is designed to record actions which are taken place in small to medium level of hotels like customer enquiries, check in, advance receipts, balance payment, check out, invoicing, etc. The software is designed as per the requirements of Hotels Management System in secured way with less process time needed in generating output reports.",
      bannerImage: "/assets/images/product/hotel-management-system.png",
      line1: "",
      line2: "",
    },
    {
      id: "inventory-management-system",
      icon: "/assets/images/app_landing2/service/2-3.png",
      title: "Inventory Software",
      short:
        "The Inventory Management Software is designed specially to meet the prerequisite of medium sized...",
      details:
        "The Inventory Management Software is designed specially to meet the prerequisite of medium sized and corporate enterprises. A well organized business house needs to maintain well-timed and accurate information about receipt of goods, sale & purchase, disposition, goods transfer, and status of stock at one point of time. A precise inventory control system is required to deal with the above operations smoothly and accurately",
      bannerImage: "/assets/images/product/Inventory-management-system.png",
      line1: "",
      line2: "",
    },
    {
      id: "hospital-management-system",
      icon: "/assets/images/app_landing2/service/2-4.png",
      title: "Hospital Management System",
      short:
        "Hospital Management & Billing software is designed which regulates all functions & working of Hospital...",
      details:
        "Hospital Management & Billing software is designed which regulates all functions & working of Hospital with 50-1000 beds. The software registers a patient, recommends him the required treatment, and gets admitted. Software module includes right from Patient Registration, OPD, Ward Management, Radiology, Pathology, Pharmacy, Store, Inventory, HR & Payroll, Finance & Accounts, Help Desk, kitchen & Laundry, MRD, Dash board, MIS Reports you have all the modules integrated and functional with our NiDiK software package.",
      bannerImage: "/assets/images/product/hospital-management-system.png",
      line1: "",
      line2: "",
    },
    {
      id: "payroll-management-system",
      icon: "/assets/images/app_landing2/service/2-5.png",
      title: "Payroll Management Software",
      short:
        "Payroll Management software is a comprehensive application developed to perform the Payroll tasks...",
      details:
        "Payroll Management software is a comprehensive application developed to perform the Payroll tasks of an organization in an effective and simple and flexible way. This software has live productively executed in manufacturing Companies, Multi-location companies and numerous Textile Mills and this can be seamlessly integrated with the organization's Intranet providing leave processing and salary / HR information to the employees.",
      bannerImage: "/assets/images/product/payroll-management-software.png",
      line1: "",
      line2: "",
    },
    {
      id: "retail-management-system",
      icon: "/assets/images/app_landing2/service/2-6.png",
      title: "Retail Management System",
      short:
        "NiDiK offers comprehensive retail management solution that is suitable for both large & small company...",
      details:
        "NiDiK offers comprehensive retail management solution that is suitable for both large & small company. It has been designed to automate, manage and look after the over-all processing of even large-scale libraries. This software is capable of managing product, Returns and Balances of payments due from Members, generating various Reports for Record-Keeping and Review purposes, according to end user requirements.",
      bannerImage: "/assets/images/product/retail-management-system.png",
      line1: "",
      line2: "",
    },
    {
      id: "college-management-system",
      icon: "/assets/images/app_landing2/service/2-6.png",
      title: "College Management System",
      short:
        "School/College Management System Software is a complete solution for managing a school/college...",
      details:
        "School/College Management System Software is a complete solution for managing a school/college online, in other words an enhanced tool that assists in organizing the day-to-day activities of a school/college. This School/college Management software has been engineered considering the vital needs of all the stakeholders in the school /college system, namely, principal, administrator, teachers, students, parents, security and others.",
      bannerImage: "/assets/images/product/college-management-system.png",
      line1: "",
      line2: "",
    },
  ],

  MemberData: [
    {
      img: "/assets/images/app_landing2/team/1.png",
      name: "ken pitersan",
      role: "Seniour UI/Xi Designer",
    },
    {
      img: "/assets/images/app_landing2/team/2.png",
      name: "ken pitersan",
      role: "Seniour UI/Xi Designer",
    },
    {
      img: "/assets/images/app_landing2/team/3.png",
      name: "ken pitersan",
      role: "Seniour UI/Xi Designer",
    },
    {
      img: "/assets/images/app_landing2/team/4.png",
      name: "ken pitersan",
      role: "Seniour UI/Xi Designer",
    },
  ],
  PricingData: [
    {
      title: "Free",
      features: ["Easy Installations", "Unlimited support", "Free Forever"],
      price: 0,
    },
    {
      title: "Medium",
      features: ["Easy Installations", "Unlimited support", "Free Forever"],
      price: 99,
    },
    {
      title: "Business",
      features: ["Easy Installations", "Unlimited support", "Free Forever"],
      price: 999,
    },
    {
      title: "Medium",
      features: ["Easy Installations", "Unlimited support", "Free Forever"],
      price: 99,
    },
  ],
};
