module.exports = {
    FeatureData: [
        {
            img: '/assets/images/saas1/feature/1.png',
            heading: 'Reliability',
            layout: '',
            desc: '...'
        },
        {
            img: '/assets/images/saas1/feature/2.png',
            heading: 'Cost-Effectiveness',
            layout: '',
            desc: '...'
        },
        {
            img: '/assets/images/saas1/feature/3.png',
            heading: 'Robust Implementation Support',
            layout: '',
            desc: '...'
        },
        {
            img: '/assets/images/saas1/feature/4.png',
            heading: 'Flexibility',
            layout: '',
            desc: '...'
        },
        {
            img: '/assets/images/saas1/feature/5.png',
            heading: 'Scalability & Usability',
            layout: '',
            desc: '...'
        },
        {
            img: '/assets/images/saas1/feature/6.png',
            heading: 'Mobile Accessibility',
            layout: '',
            desc: '...'
        },
    ],

    NavData :[
        {
            img: '/assets/images/saas1/tab-icon/installation.png',
            title: 'Initializing'
        },
        {
            img: '/assets/images/saas1/tab-icon/001-tap.png',
            title: 'Add Instances'
        },
        {
            img: '/assets/images/saas1/tab-icon/button.png',
            title: 'Configuration'
        },
        {
            img: '/assets/images/saas1/tab-icon/002-settings.png',
            title: 'API Setting'
        },
        {
            img: '/assets/images/saas1/tab-icon/key-1.png',
            title: 'Security'
        }
    ],

    ContentData :[
        {
            img: '/assets/images/saas1/tab/Initializing.png',
            title: 'Initializing'
        },
        {
            img: '/assets/images/saas1/tab/Add-instant.png',
            title: 'Add Instances'
        },
        {
            img: '/assets/images/saas1/tab/Configuration.png',
            title: 'Configuration'
        },
        {
            img: '/assets/images/saas1/tab/Api.png',
            title: 'API Setting'
        },
        {
            img: '/assets/images/saas1/tab/Security.png',
            title: 'Security'
        }
    ]
}